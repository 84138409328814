import { useEffect, useRef, useState } from "react";
import {Logo} from '../Components/Logo'
import './Showcase.css'
import { SplitCard } from "../Components/SplitCard";

export default function Showcase() {
  return (
    <div className="Showcase Page">
      <header className="Title" id="title">
        <Logo full shadows={7}>
          {"motiv"}
        </Logo>
        {/* <LogoBar id="bar1" text={"M O T I V"} />
        <LogoBar id="bar2" text={"M O T I V"} /> */}
      </header>
      <nav>
        <a href="#title" class="Home"><Logo></Logo></a>
      </nav>
      <div className="Grid Content">
        <section className="">
          <h1 className="">motiv aesthetic</h1>
          <h2 className="">motiv aesthetic</h2>
          <div>
          <h3 className="">motiv aesthetic</h3>
          <h4 className="">motiv aesthetic</h4>
          <h5 className="">motiv aesthetic</h5>
          <h6 className="">motiv aesthetic</h6>

          <p>Lorem ipsum</p>
          <p className="FontAlt">Lorem ipsum</p>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            eu nibh mauris. Suspendisse vehicula, lectus vitae tempor iaculis,
            mi orci egestas erat, et pulvinar nunc est vitae ligula. In aliquam
            euismod ipsum sit amet rhoncus. Aliquam non massa sed enim
            ullamcorper rhoncus sed egestas nibh. Integer aliquet augue dui, non
            rhoncus sem tristique vel. Integer fringilla ipsum sapien, ac
            efficitur tortor ultrices a. Donec quis rutrum quam. Ut id aliquet
            lorem. Donec semper aliquam gravida. Donec id neque suscipit, cursus
            mi et, varius lacus. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Ut at odio consectetur
            leo laoreet euismod non eu felis. Aliquam dictum, justo at imperdiet
            lacinia, urna lectus cursus urna, nec pellentesque eros nisi et
            enim. Mauris lacinia augue eu purus condimentum, a pellentesque dui
            vehicula.
          </span>
          </div>
        </section>
        <section>
          <h1>Interactive</h1>
          <div>
            <button>Hello</button>
            <input type="button" value="Input" />
            <input type="submit" value="Submit" />
          </div>
        </section>

        <section>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
            eu nibh mauris. Suspendisse vehicula, lectus vitae tempor iaculis,
            mi orci egestas erat, et pulvinar nunc est vitae ligula. In aliquam
            euismod ipsum sit amet rhoncus. Aliquam non massa sed enim
            ullamcorper rhoncus sed egestas nibh. Integer aliquet augue dui, non
            rhoncus sem tristique vel. Integer fringilla ipsum sapien, ac
            efficitur tortor ultrices a. Donec quis rutrum quam. Ut id aliquet
            lorem. Donec semper aliquam gravida. Donec id neque suscipit, cursus
            mi et, varius lacus. Orci varius natoque penatibus et magnis dis
            parturient montes, nascetur ridiculus mus. Ut at odio consectetur
            leo laoreet euismod non eu felis. Aliquam dictum, justo at imperdiet
            lacinia, urna lectus cursus urna, nec pellentesque eros nisi et
            enim. Mauris lacinia augue eu purus condimentum, a pellentesque dui
            vehicula.
          </div>
        </section>

        {/* <section>
          <SplitCard className={'testcard'}>
            <img className="cat1"/>
            <img className="cat2"/>
            <p>Cat Test</p>
          </SplitCard>
        </section> */}
      </div>
      <footer>Copyright etc.</footer>
    </div>
  );
}


export function Logo({ full, shadows = 5, className = "", text = "motiv" }) {
  return full ? (
    <div className={"Logo Full Wide" + className}>
      <h1 className="Main">{text}
      {[...Array(shadows)].map((v, i) => (
        <h1
          className="Sub"
          key={"logo_shadow" + i}
          style={{
            animationDelay: i + "s",
            animationDuration: shadows + "s"
          }}
        >
          {text}
        </h1>
      ))}
      </h1>
    </div>
  ) : (
    <div className={"Logo Icon" + className}>
      <div className="Border">
        <div className="Border">
          <div className="Border"></div>
        </div>
      </div>
    </div>
  );
}

function LogoBar({ id, className, text }) {
  const longText = [...Array(40).fill(text + " ")];

  return (
    <div id={id} className={className}>
      <div className="object">
        <div className="face">{longText}</div>
        <div className="face">{longText}</div>
        <div className="face">{longText}</div>
        <div className="face">{longText}</div>
      </div>
    </div>
  );
}
  
import { useEffect, useRef, useState } from "react";
import {Logo} from '../Components/Logo'
import MVPlayer from "../Components/MVPlayer";
import './Main.css'

import videoPreviewDreams from '../Media/dreams_park_preview_silent.mp4'
import videoFullDreams from '../Media/dreams_park.mp4'

export default function Main() {
  return (
    <div className="Main Page">
      <header className="Title Block" id="title">
        <Logo full shadows={7}>
          {"motiv"}
        </Logo>
        {/* <LogoBar id="bar1" text={"M O T I V"} />
        <LogoBar id="bar2" text={"M O T I V"} /> */}
      </header>
      <nav>
        <a href="#title" class="Home"><Logo></Logo></a>
      </nav>
      <div className="Grid Content">
      
      <header className="Heading Block">
        <h1 className="Wide">Songs</h1>
      </header>
      <section className="Songs Block">
        <MVPlayer title='Dreams' previewVideo={videoPreviewDreams} fullVideo={videoFullDreams}/>
      </section>

      </div>
      {/* <footer>motiv.pw - 2023</footer> */}
    </div>
  );
}

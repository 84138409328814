import { useState } from 'react'
import './MVPlayer.css'

export default function MVPlayer({title,previewVideo,fullVideo}){
    
    const [full, setFull] = useState(false)

    return (<>
		{full ? 
      <div id={title} className={"Preview Full " + title} >
        <video autoPlay controls loop playsInline>
            <source src={fullVideo} type="video/mp4"/>
        </video>
    	</div>
		:
			<div id={title} className={"Preview " + title} 
				onClick={()=>{
					if(!fullVideo){
							console.error('no full video available', title)
					}
					else{
						setFull(true)
					}
				}}
			>
        <h1 className="FontAlt SongTitle">{title}</h1>
        <video autoPlay muted loop playsInline >
            <source src={previewVideo} type="video/mp4"/>
        </video>
    	</div>}
		</>)

}